@import '../core/breakpoints';

.socials {
	display: flex;
	justify-content: space-between;
	margin-bottom: 0;
	padding-left: 0;
	list-style: none;
    gap:calc(clamp(16px, -0.667rem + 5.56vw, 32px));

	.socials__item {
		margin: 0;
		a {
			text-decoration: none;
		}
        img {
            width: 20px;
            aspect-ratio: 1/1;
        }
	}
}