.devInfo {
    position: fixed;
    background-color: #6ab2ec;
    padding: 0.5rem 1rem 0.5rem 1rem;
    color: $white;
    width: 250px;
    top: 10px;
    left: 10px;
    text-align: left;
    animation: show 1s forwards ease-in-out;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    z-index: 100000;
    cursor: move;
    @media screen and (max-width:480px){
        display: none;
    }
    @keyframes show {
        0% {
            display: block;
            opacity: 0;
        }
        99% {
            opacity: 1;
        }
    }
    h4 {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding-bottom: 0.5rem;
    }
    &--local_dev {
        background: #80A023;
    }
    &--dev_stage {
        background: #6ab2ec;
    }
}