@import '../config';


.statistics {
    color: $white;
    margin-bottom: 20rem;
    padding-top: 5rem;
    position: relative;
    z-index: 10;
}

.statistics__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ul {
        list-style: none;
        text-align: center;
        padding-left: 0%;
    }
}