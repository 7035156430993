@import '../config';
@import '../core/breakpoints';

.prizes {
    .prizes__wrapper {
        max-width: 1440px;
        margin: auto;
        width: fit-content;
        padding: 90px 1rem;
    }
    .prizes__row {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width:768px){
            flex-direction: column;
        }
    }
    .prizes__col {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .prizes__col--img {
        flex: 4;
        max-width: 550px;
        width: 60%;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
    .prizes__count {
        font-size: 90px;
        font-weight: 750;
        line-height: 108px;
        text-align: center;
        color: $light;
    }
    .prizes__name {
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        color: $light;
    }
}
