@import '../config';

.custom-file-container {
	max-width: 640px;
	width: 100%;
	margin: 2rem auto 0;
    text-align: center;
	.custom-file-container__custom-file {
		.help-block,
		.text-danger {
			text-align: center;
		}
	}

	.custom-file-container__custom-file__custom-file-input {
		position: absolute;
	}

	.custom-file-container__custom-file__custom-file-control {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction:row-reverse;
		position: relative;
		top: auto; left: auto; right: auto;
		border: 0;
		background-color: transparent;
		height: auto;
		color: #3E1314;
		font-size: 14px;
		text-align: center;
        gap: 1rem;
        padding: 1rem 0;
        @media screen and (max-width:480px){
            flex-direction: column;
        }
	}

	.custom-file-container__custom-file {
		height: auto;
        text-align: center;
        overflow: hidden;
	}

	.custom-file-container__custom-file__custom-file-control__button {
		position: relative;
		top: auto; left: auto; right: auto;
		height: auto;
		padding: 1rem 4rem;
		font-size: 1.2rem;
	}

	.custom-file-container__image-preview {
		overflow: hidden;
		height: 0;
		margin-top: 2rem;
	}

	.custom-file-container__image-preview--active {
		background-size: contain;
		background-color: transparent;
		height: 250px;
	}
    .uploadLabel {
        span:first-of-type {
            color: $white;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            white-space: nowrap;
            padding: 12px 20px;
        }
    }
}
