@import "../config";
@import "../core/breakpoints";

.button {
    display: inline-flex;
    text-align: center;
    text-decoration: none;
    font-family: $font-optima;
    font-size: 18px;
    font-weight: 800;
    line-height: 15px;
    cursor: pointer;
    border: 0;
    text-transform: uppercase;
    margin-bottom: -1px;
    @include max480px {
        font-size: 20px;
    }
}

.button--gold {
    padding: 20px 40px;
    background: #EABB66;
    font-family: $font-optima;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    color: $red;
}

.button--red {
    padding: 20px 40px;
    background: $red;
    font-family: $font-optima;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    color: $white;
}

.button--small {
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    padding: 1rem 2rem;
}

.button--center {
    display: flex;
    margin: auto;
    width: fit-content;
}