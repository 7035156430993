.academy {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    margin-top: -100px;
    margin-bottom: 100px;
    .video__bg {
        min-width: 100%;
        z-index: -1;
        position: relative;
        -webkit-mask-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 5%,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 100%
        );
        mask-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 5%,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 100%
        );
        video {
            transform: scale(1.5);
            position: relative;
            top: -100px;
            @media screen and (min-width: 1440px) {
                transform: scale(1);
            }
        }
        @media screen and (max-width: 1060px) {
            margin-top: -450px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
        @media screen and (min-width: 1440px) {
            -webkit-mask-image: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0,
                #000 5%,
                #000 75%,
                rgba(0, 0, 0, 0) 89%
            );
            mask-image: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0,
                #000 5%,
                #000 75%,
                rgba(0, 0, 0, 0) 89%
            );
        }
    }
    .video__bg--phone {
        display: none;
        pointer-events: none;
        position: relative;
        -webkit-mask-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 5%,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 100%
        );
        mask-image: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 5%,
            rgba(0, 0, 0, 1) 95%,
            rgba(0, 0, 0, 0) 100%
        );
        @media screen and (max-width: 768px) {
            margin-top: -700px;
            display: block;
            width: 100%;
        }
        @media screen and (max-width: 640px) {
            margin-top: -500px;
        }
        @media screen and (max-width: 480px) {
            margin-top: -450px;
        }
        @media screen and (max-width: 360px) {
            margin-top: -400px;
        }
        &:after {
            content: "";
            position: absolute;
            top: -50px;
            left: 0;
            width: 100%;
            background: linear-gradient(
                180deg,
                rgba(2, 0, 36, 0) 0%,
                rgba(0, 0, 0, 1) 40%,
                rgba(0, 0, 0, 1) 60%,
                rgba(0, 212, 255, 0) 100%
            );
            height: 100px;
        }
    }
    .academy__wrapper {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 1040px;
        width: 100%;
        transition: 0.5s;
        z-index: 10;
        h1 {
            font-size: 5.54256rem;
            font-style: normal;
            font-weight: 400;
            color: $light;
            line-height: 90%;
            margin-bottom: 1rem;
            @media screen and (max-width: 1060px) {
                font-size: 3rem;
            }
        }
        p {
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.625rem;
            color: $light;
            text-align: center;
            @media screen and (max-width: 540px) {
                max-width: 18rem;
                margin: auto;
            }
        }
        .button {
            width: fit-content;
            margin: auto;
            margin-top: 2rem;
        }
        .image--widthButton {
            position: relative;
            .button--win {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translate(-50%);
                @media screen and (max-width:400px){
                    bottom: -70px;
                }
            }
        }

        @media screen and (max-width: 1060px) {
            position: relative;
            transform: none;
            padding-top: 8rem;
            top: 0;
            left: 0;
            min-height: 600px;
            z-index: 100;
        }
    }
    .academy__intro {
        @media screen and (max-width: 1060px) {
            padding-top: 12rem;
            box-sizing: border-box;
        }
    }
    .academy__wrapper--slider {
        top: 50%;
    }
    .hide {
        opacity: 0;
        pointer-events: none;
        display: none;
    }
    @media screen and (max-width: 480px) {
        margin-bottom: 0;
    }
    @media screen and (min-width: 1440px) {
        margin-top: -160px;
    }
}
.academy__slider {
    text-align: center;
    p {
        color: $lightbrown !important;
        font-size: 1.25rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.625rem;
        @media screen and (max-width: 1060px) {
            max-width: 80vw;
            margin: auto;
            font-size: 1rem;
        }
    }
    .slide__img {
        max-height: 300px;
        margin: auto;
        @media screen and (max-width: 768px) {
            width: 100%;
            max-height: none;
            margin: 0;
        }
    }
    .slide__video {
        max-height: 300px;
        margin: auto;
        box-shadow: 5px 10px 20px 2px rgba(0, 0, 0, 0.5);
        border: 2px solid $lightbrown;
        border-radius: 10px;
        width: fit-content;
        margin-top: 1rem;
        max-width: 90%;
    }
    .slide {
        p {
            @media screen and (max-width: 480px) {
                max-width: none;
                padding: 0 1rem;
            }
        }
        &--1,
        &--3 {
            .slide__img {
                @media screen and (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
}

.academy__wrapper {
    position: relative;
    .arrow--right {
        position: absolute;
        top: 50%;
        right: 0px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &.slick-disabled {
            opacity: 0.25;
        }
    }
    .arrow--left {
        position: absolute;
        top: 50%;
        left: 0px;
        cursor: pointer;
    }
    .arrow--left,
    .arrow--right {
        @media screen and (max-width: 1060px) {
            display: none;
        }
    }
    .swipe {
        display: block;
        position: relative;
        top: -3rem;
        margin: auto;
        background: rgba($color: #1b0808, $alpha: 0.5);
        padding: 1rem;
        border-radius: 20px;
        width: min-content;
        #arrow--left,
        #arrow--right {
            opacity: 1;
        }
        #swipe-arrow-left {
            position: absolute;
            width: 50%;
            height: 100%;
            left: 0;
            top: 0;
            &.slick-disabled ~ .swipe__icon #arrow--left {
                opacity: 0.25;
            }
        }
        #swipe-arrow-right {
            position: absolute;
            width: 50%;
            height: 100%;
            right: 0;
            top: 0;
            &.slick-disabled ~ .swipe__icon #arrow--right {
                opacity: 0.25;
            }
        }
        #swipe__icon {
            fill: $lightbrown;
        }
        .slick-arrow {
            fill: $lightbrown;
            opacity: 1;
        }
        @media screen and (min-width: 1060px) {
            display: none;
        }
    }
}

.slide_imgSlider {
    position: relative;
    width: 550px;
    height: 300px;
    margin: 2rem auto;
    box-sizing: border-box;
    .imgSlider__slide {
        width: 240px;
        height: 329px;
        cursor: pointer;
        transition: 0.2s;
        &--center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 104;
        }
        &--left {
            position: absolute;
            top: 53%;
            left: -7%;
            transform: translate(0, -50%);
            z-index: 105;
        }
        &--right {
            position: absolute;
            top: 55%;
            right: 0;
            transform: translate(0, -50%);
            z-index: 103;
        }
        @media screen and (min-width:768px){
            &:hover {
                z-index: 110;
                width: 280px;
                height: 384px;
            }
        }

        @media screen and (min-width:1440px){
            width: 270px;
            height: 370px;
        }
    }
    @media screen and (max-width: 768px) {
        display: block;
        height: 330px;
        overflow: hidden;
        margin-top: 1rem;
        max-width: 400px;
        margin: auto;
        width: 100%;
        .imgSlider__slide {
            max-width: 15rem;
            height: auto;
            width: auto;
            &--center {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 110;
                pointer-events: none;
            }
            &--left {
                max-height: 25rem;
                position: absolute;
                top: 50%;
                left: -30%;
                transform: translate(0, -50%);
                filter: brightness(0.3);
            }
            &--right {
                max-height: 25rem;
                position: absolute;
                top: 50%;
                right: -30%;
                transform: translate(0, -50%);
                filter: brightness(0.3);
            }
            @media screen and (min-width:400px){
                &--left {
                    left: 0;
                }
                &--right {
                    right: 0;
                }
            }
        }
        @media screen and (min-width: 500px) and (max-width: 768px) {
            overflow: visible;
        }
    }
}

.slide_imgSlider--third {
    @media screen and (min-width:768px){
        width: 800px;
        .imgSlider__slide {
            width: 550px;
            height: 385px;
            &--center {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
                z-index: 110;
            }
            &--right {
                max-height: 25rem;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }
            @media screen and (min-width:768px){
                &:hover {
                    z-index: 110;
                    width: 580px;
                    height: 406px;
                }
            }
        }
        @media screen and (max-width:1440px){
            height: 300px;
            width: 700px;
            .imgSlider__slide {
                width: 400px;
                height: 280px;
                @media screen and (min-width:768px){
                    &:hover {
                        z-index: 110;
                        width: 429px;
                        height: 300px;
                    }
                }
            }
        }
    }
}

.academy__sliderDots {
    z-index: 100;
    position: relative;
    width: 100%;
    height: 40px;
    .slick-dots {
        position: static;
    }
    .slick-dots li.slick-active button:before {
        color: $lightbrown;
    }
    .slick-dots li button:before {
        color: rgba(233, 184, 96, 0.4);
        font-size: 10px;
    }
    @media screen and (min-width: 1440px) {
        top: -170px;
    }
    @media screen and (max-width: 768px) {
        position: relative;
        top: -150px;
    }
    @media screen and (max-width: 768px) {
        top: -100px;
        .slick-dots li {
            width: 30px;
        }
        .slick-dots li button:before {
            width: 30px;
            height: 30px;
            font-size: 15px;
        }
    }
}

.academy__chocoBg--right {
    position: absolute;
    right: 0;
    top: 40%;
    width: 180px;
    @media screen and (max-width: 1200px) {
        display: none;
    }
}

.academy__chocoBg--left {
    position: absolute;
    left: 0;
    top: 15%;
    width: 180px;
    @media screen and (max-width: 1200px) {
        display: none;
    }
}
