@import '../config';
@import '../core/breakpoints';

.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
    width: 300px;
    background: $red;
    height: calc(100% - 87px);
    z-index: 10000;
    width: 0;
    transition: 300ms ease-in-out;
    overflow: hidden;

    &.open {
        width: 300px;
    }
    .nav__list {
        flex-grow: 1;
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        .nav__link {
            color: $white;
            text-decoration: none;
            font-size: 24px;
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: 600;
        }
    }
    .sidebar__shop {
        flex-shrink: 1;
        display: flex;
        justify-content: center;
        gap: 1rem;
        text-decoration: none;
        padding: 2rem 1rem;
        img {
            width: 32px;
        }
        span {
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            line-height: 27px;
            text-align: left;
        }
    }
}