.errorPage {
	.errorPage__wrapper {
		margin: 5rem auto;
	}

	.errorPage__copy {
		text-align: center;
		font-size: 1.2rem;
	}
}
