@import '../config';
@import '../core/breakpoints';

.header {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    .header__wrapper {
        max-width: none;
        margin: auto;
        display: flex;
        padding: 28px 1rem;
        align-items: center;
        justify-content: space-between;
        transition: 300ms ease-in-out;
        background: $red;
        @media screen and (max-width:480px){
            padding: 1rem;
        }
    }
    .header__hamburger {
        flex:1;
        display: flex;
        justify-content: flex-end;
        z-index: 1000;
        @media screen and (min-width:940px){
            display: none;
        }
    }
}

.header__socials {
    flex: 1 150px;
    display: flex;
    gap: 2rem;
    .socials__image {
        width: 24px;
        height: 24px;
        path {
            fill: $white;
        }
    }
}

.header__shop {
    flex: 1 150px;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    text-decoration: none;
    @media screen and (max-width:940px){
        display: none;
    }
    span {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        line-height: 27px;
        text-align: left;
    }
}