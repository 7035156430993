@import '../config';
@import '../core/helpers';
@import '../core/breakpoints';

.header__nav {
    flex-grow: 1;
    flex: 5;
    @media screen and (max-width:940px){
        display: none;
    }
	.nav__list {
		display: flex;
		justify-content: center;
        gap: 1rem;
		list-style: none;
		margin-bottom: 0;
		padding-left: 0;
	}

	.nav__item {
		display: flex;
		align-items: center;
		margin: 0 1em;
		@include max480px {
			margin: 0 0.75em;
		}
	}

	.nav__item--active {
		.nav__link {
			color: $gold;
		}
	}

	.nav__link {
		color: $white;
		text-decoration: none;
		text-transform: uppercase;
        font-size: 13px;
        font-weight: 700;
        line-height: 20px;
        white-space: nowrap;
		@extend .trans;
		@include max480px {
			font-size: 0.9em;
			text-align: center;
		}

		&:hover {
			color: $gold;
		}
	}
}