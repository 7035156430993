@import '../config';

.success {
    position: relative;
    .success__wrapper {
        position: relative;
        z-index: 10;
        max-width: 1440px;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 10rem;
    }
    h1 {
        color: $white;
        font-size: 48px;
        font-weight: 700;
        line-height: 57px;
        text-align: center;
        text-transform: uppercase;
    }
    h2 {
        color: $white;
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;

    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: url(../../images/content/cover/stars.png);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
    }
    p {
        color: $white;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
    }
    .success__receiptBox {
        border-top: 1px solid $gold;
        border-bottom: 1px solid $gold;
        padding: 8px;
        width: 100%;
        max-width: 500px;
    }
}
