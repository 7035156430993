@import '../config';
@import '../core/breakpoints';


.cover {
    position: relative;
    z-index: 1;
    padding-top: 8rem;
    @media screen and (max-width:768px){
        padding-top: 3rem;
    }
    padding-top: calc(clamp(120px, 1.014rem + 7.21vw, 200px));
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: url(../../images/content/cover/stars.png);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: -1;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
    }
    .cover__wrapper {
        width: 100%;
        display: flex;
        @media screen and (max-width:768px){
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    h1 {
        font-family: $font-optima;
        font-size: 40px;
        font-size: calc(clamp(30px, 0.833rem + 5.56vw, 40px));
        font-weight: 500;
        line-height: 110%;
        text-align: center;
        background: linear-gradient(280.63deg, #E9D7BB -5.64%, #F6D59C 16.35%, #E7AE51 39.61%, #F6D59C 60.71%, #E7AE51 80.05%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        color: transparent;
        white-space: nowrap;
        margin: 40px 0 20px;
        svg {
            @media screen and (max-width:768px){
                display: none;
            }
        }
    }
    .cover__col {
        flex: 2;
        img {
            max-width: 100%;
        }
        &:first-child {
            text-align: right;
        }
        @media screen and (max-width:768px){
            &:first-child {
                max-width: 300px;
            }
            &:nth-child(3) {
                padding-bottom: 3rem;
            }
        }
    }
    .cover__col--text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin: 0 -3rem;
    }
    .cover__date {
        text-align: center;
        color: $white;
        padding: 1rem;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.8px;
        text-align: center;
        strong {
            font-weight: 700;
        }
    }
}