.alerts {
    position: fixed;
    z-index: 100;
    right: 20px;
    bottom: 3rem;
    max-width: 640px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    @media screen and (max-width:480px){
        bottom: 3rem;
        right: 0;
    }
    * {
        box-sizing: border-box;
    }
}

.alert {
    position: relative;
    padding: 1rem 2rem 1rem 1rem;
    color: $white;
    width: 100%;
    top: 20px;
    text-align: left;
    animation: show 1s forwards ease-in-out;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    @media screen and (max-width:480px){
        justify-content: flex-start;
        text-align: center;
        padding: 1rem;
        padding-right: 2rem;
        text-align: left;
    }
    @keyframes show {
        0% {
            display: block;
            opacity: 0;
        }
        99% {
            opacity: 1;
        }
    }
    h4 {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        padding-bottom: 0.5rem;
    }
    .alert__icon {
        margin: 1rem 2rem 1rem 1rem;
        width: 100px;
        @media screen and (max-width:480px){
            margin: 0 1rem 0 0;
        }
    }
    .alert__closeBtn {
        right: 20px;
        top: 20px;
        position: absolute;
        color: white;
        font-weight: bold;
        font-size: 22px;
        cursor: pointer;
        transition: 0.3s;
        &:hover {
            filter: brightness(0.8);
        }
    }
    &.alert--danger {
        border-top: 5px solid #F04438;
        background: #FFE2E0;
        color: #F04438;
        h4 {
            color: #F04438;
        }
        .alert__closeBtn path {
            fill: #F04438;
        }
    }
    &.alert--success {
        border-top: 5px solid #97BF23;
        background: #ECFFB7;
        color: #80A023;
        h4 {
            color: #80A023;
        }
        .alert__closeBtn path {
            fill: #80A023;
        }
    }
    &.alert--info {
        background-color: #6ab2ec;
        color: #023d6d;
        h4 {
            color: #023d6d;
        }
        .alert__closeBtn path {
            fill: #023d6d;
        }
    }
    &.alert--warning {
        background-color: #f9deb7;
        color: #c67700;
        h4 {
            color: #c67700;
        }
        .alert__closeBtn path {
            fill: #c67700;
        }
    }
    &.closed {
        animation: hide 1s forwards;
        @keyframes hide {
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
                display: none;
            }
        }
    }
}