@import '../config';

.competitionForm {
	z-index: 1;
    position: relative;
    background: linear-gradient(225deg, #E9D7BB 0%, #F6D59C 25.66%, #E7AE51 52.81%, #F6D59C 77.43%, #E7AE51 100%);
    margin-bottom: 5rem;
    h2 {
        color: $red;
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;        
    }
    h3 {
        font-size: 2rem;
        font-weight: 400;
        line-height: 43px;
        letter-spacing: 0em;
        text-align: center;
        font-weight: 700;

    }
    .competitionForm__wrapper {
        padding: 10rem 1rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: auto;
        @media screen and (max-width:480px){
            padding-top: 2rem;
        }
    }
    &.disabled .competitionForm__box {
        pointer-events: none;
        opacity: 0.5;
    }
    &.disabled .competitionForm__tooltip {
        display: block;
    }
    .competitionForm__wave {
        width: 100%;
        vertical-align: top;
        object-position: top;
        margin-top: -1px;
        @media screen and (max-width:480px){
            display: none;
        }
    }
    .competitionForm__stars {
        top: 4rem;
        img {
            width: 1440px;
        }
    }
}
.competitionForm .form {
    max-width: 600px;
    width: 100%;
    .checkbox {
        max-width: 320px;
        label {
            font-size: 0.75rem;
            padding-left: 2rem;
        }
        .helper {
            background-color: $white;
            width: 0.875rem;
            height: 0.875rem;
            top: auto;
        }
    }
    .form__checkbox {
        align-items: flex-start;
    }
}

.competitionForm__footer {
    color: #3E1314;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.competitionForm--over {
    h3 {
        color: $brown;
    }
    .prize__winner {
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 700;
        color: $red;
    }
}

.competitionForm__rules {
    color: $red;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.6px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
}

.competitionForm__products {
    margin: auto;
    display: block;
    margin-bottom: -12rem;
    max-width: 100%;
}

.competitionForm__topWave {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.competitionForm__bottomWave {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.competitionForm--over {
    margin-bottom: 0;
    .competitionForm__wrapper {
        padding: 10rem 1rem;
    }
}