@import '../config';
@import '../core/breakpoints';


//variables

$danger: #ec5a3b;

.form {
    .form__row {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
        width: 100%;
        .form__col {
            flex: 1 250px;
            padding: 1rem 0;
        }
        @media screen and (max-width:658px){
            gap: 0
            ;
        }
    }
    .form__row--upload {
        display: flex;
        justify-content: center;
        align-items: center;
        .form__control {
            height: auto;
        }
        h3 {
            font-size: 2rem;
            text-align: center;
            display: block;
            margin: auto;
            font-size: 18px;
            font-weight: 700;
            line-height: 21.6px;
            text-align: center;
            color: $red;
            text-transform: uppercase;
        }
    }
    .form__control {
        max-width: 100%;
        height: auto;
        position: relative;
        &.has-error {
            .form__label {
                color: $danger;
            }
        }
    }
    .form__label {
        display: none;
        position: absolute;
        pointer-events: none;
        bottom: 10px;
        left: 0;
        font-size: 16px;
        transition: all 0.3s ease;
        color: $white;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
    }
    .form__input {
        display: block;
        width: 100%;
        height: 50px;
        font-family: $font-optima;
        border-radius: 0;
        border: none;
        font-size: 17px;
        border: 1px solid #3E1314;
        background: $white;
        color: #3E1314;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding: 18px 20px;
        &::placeholder {
            font-size: 14px;
            font-weight: 700;
            line-height: 19.2px;
            text-align: left;
            color: rgba($color: #3E1314, $alpha: 0.5);
        }
        &:focus-visible {
            outline: none;
        }
    }
    .form__inputUnderline {
        position: absolute;
        bottom: 0;
        height: 2px;
        width: 100%;
        &:before{
            position: absolute;
            content: "";
            height: 2px;
            width: 100%;
            background: $gold;
            transform: scaleX(0);
            transform-origin: center;
            transition: transform 0.3s ease;
          }
    }
    .form__input--textarea {
        height: auto;
        border-radius: 20px;
        padding-top: 1rem;
    }
    .form__errors {
        padding-left: 0;
    }
    .form__error {
        margin-top: 10px;
        position: static;
        z-index: 10;
        width: 100%;
        color: $danger;
        display: block;
        height: max-content;
        background: #E71827;
        color: $white;
        font-family: $font-optima;
        font-size: 13px;
        font-weight: 700;
        line-height: 15.6px;
        text-align: left;
        padding: 5px;
    }
    .form__row--checkbox {
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        .form__control {
            max-width: 400px;
            width: 100%;
            height: auto;
        }
    }
    .form__checkbox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        label {
            font-size: 0.875rem;
            font-weight: 500;
            color: $brown;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            gap: 10px;
            width: fit-content;
            input:is([type="checkbox"]) {
                appearance: none;
                background-color: transparent;
                border: 1px solid $brown;
                margin: 0;
                font: inherit;
                color: currentColor;
                width: 1.875rem;
                height: 1.875rem;
                border-radius: 0.15em;
                transform: translateY(-0.075em);
                display: grid;
                place-content: center;
                position: relative;
                flex-shrink: 0;
                &::before {
                    content: url("../../images/ui/forms/check.svg");
                    width: 25px;
                    height: 16px;
                    transform: scale(0) translate(-50%, -50%);
                    transition: 120ms transform ease-in-out;
                    position: absolute;
                    transform-origin: bottom left;
                    top: 40%;
                    left: 50%;
                }
/*                 &:checked {
                    background-color: $dark-blue;
                } */
                &:checked::before {
                    transform: scale(1) translate(-50%, -50%);
                }
                &:focus {
                    outline: max(2, 0.15em) solid currentColor;
                    outline-offset: max(2, 0.15em);
                }
            }
        }
    }
    .button--submit {
        position: relative;
        z-index: 0  ;
        display: block;
        margin: 2rem auto;
        width: fit-content; 
        padding: 1rem 36px;
        span {
            font-size: 36px;
            line-height: 43.2px;
        }
        @media screen and (max-width:480px){
            width: 100%;
        }
    }
    .hidden {
        display: none;
    }
}