@import "../config";
@import "../config";


.winners__head {
    display: flex;
    max-width: 1420px;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding-bottom: 5rem;
    @media screen and (max-width:940px){
        flex-direction: column;
    }
    h1 {
        font-size: 35px;
        font-weight: 700;
        line-height: 42px;
        text-align: center;
    }
    .winners__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
            color: $white;
            max-width: 525px;
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;

        }
    }
    .winners__col--text {
        padding-top: 3rem;
        flex-shrink: 0;
        @media screen and (max-width:940px){
            margin-top: 6rem;
        }
    }
    .winners__col--img {
        flex-shrink: 1;
        img {
            width: 100%;
            max-width: 100%;
        }
    }
}

.winners__winners {
    background: linear-gradient(225deg, #E9D7BB 0%, #F6D59C 25.66%, #E7AE51 52.81%, #F6D59C 77.43%, #E7AE51 100%);
    padding: 0rem;
    .winners__wrapper {
        padding: 1rem;
    }
    h2 {
        color: $brown;
        font-weight: 700;
        &::after {
            content: none;
        }
    }
    h3 {
        text-align: center;
        color: $brown;
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 600;
    }
    .winners__box {
        background: $white;
        width: 100%;
        max-width: 350px;
        text-align: center;
        margin: 1rem auto 2rem;
        border: 1px solid $red;
        border-radius: 30px;
        padding: 0 2rem;
        color: #C61624;
        font-size: 18px;
        font-weight: 700;
        line-height: 21.6px;
        text-align: left;
        display: flex;
        flex-direction: column;
        max-height: 400px;
        overflow: hidden;
        .winners__winner {
            width: fit-content;
        }
    }
    .winner__boxInner {
        padding: 2rem 0;
        text-align: center;
    }
}

.winners__wave {
    width: 100%;
}

.winners__wave--top {
    margin-top: -2px;
}

.winners__wave--bottom {
    margin-bottom: -10px;
}

.winners__after {
    .winners__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .winners__products {
        margin: auto;
        display: block;
        margin-top: calc(max(-10vw, -10rem));
        margin-bottom: 10rem;
        position: relative;
        z-index: 10;
        max-width: calc(min(100vw, 400px));
    }
}
.winners__before {
    min-height: 80vh;
    margin: 3rem auto;
    max-width: 1440px;
    padding: 0 1rem;
    h1 {
        color: $light;
        font-size: 39px;
        font-weight: 700;
        line-height: 46.8px;
        text-align: center;
        @media screen and (max-width:480px){
            font-size: 30px;
        }
        @media screen and (max-width:350px){
            font-size: 25px;
        }
    }
    .winners__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
            max-width: 600px;
            width: 100%;
        }
    }
    .winners__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        margin: auto;
        @media screen and (max-width:940px){
            flex-direction: column;
            padding-top: 4rem;
            gap: 3rem;
        }
        p {
            font-size: 25px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            color: $white;
        }
        .winners__date {
            font-size: 35px;
            font-weight: 700;
            line-height: 42px;
            text-align: center;
            color: $white;
            margin-bottom: 3rem;
        }
    }
}