@import '../core/helpers';

.cookies {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: rgba(88, 54, 34, 0.9);
	z-index: 1000;
	@extend .trans;

	&:hover {
		background-color: rgba(88, 54, 34, 1);
	}

	.cookies__row {
		justify-content: center;
		align-items: center;
		padding: 1em 0;
	}

	.cookies__col--buttons {
		@include max480px {
			text-align: center;
		}
	}

	.cookies__message {
		text-align: center;
		padding: 0 1rem;
		margin-bottom: 0.5rem;
		color: $white;
	}

	.button {
		padding: 0.5rem 1rem;
		font-size: 1rem;
		margin-left: 2em;
		@include max480px {
			margin-left: 0;
		}
	}

	.button--transparent {
		color: $white;
	}
}
