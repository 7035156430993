@import '../config';
@import '../core/breakpoints';

.mechanics {
	padding: 90px 0;
	position: relative;
	background-color: transparent;
	z-index: 1;
	.mechanics__row {
		justify-content: center;
		margin: 2rem 0;
        position: relative;
		@include max1024px {
			flex-flow: row wrap;
		}

		@include max480px {
			margin: 2.5rem 0;
		}

	}
	.mechanics__col {
        cursor: pointer;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(33% - 5rem);
		margin: 0 2.5rem;
        text-decoration: none;
		@include max1024px {
			max-width: 300px;
			width: 100%;
			margin-bottom: 4rem;
		}

		&:last-child {
			@include max640px {
				margin-bottom: 0;
			}
		}
	}

    .mechanics__image {
        height: 265px;
    }
	.mechanics__step {
        width: 58px;
        height: 58px;
        margin-bottom: 2rem;
	}

	.mechanics__info {
		color: $white;
        font-size: 22px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        max-width: 300px;
        .underlined {
            text-decoration: underline;
        }
	}

	.mechanics__note {
		text-align: center;
		margin: 1rem 0 1.5rem;
		font-size: 1rem;
		color: $black;
	}
    .mechanics__date {
        text-align: center;
        span {
            display: block;
            font-weight: 500;
            font-size: 1.25rem;
        }
        a {
            font-size: 0.875rem;
            font-weight: 500;
        }
    }
    .mechanics__chocoBg {
        position: absolute;
        left: 0;
        top: 0%;
        width: 240px;
        @media screen and (max-width:768px){
            display: none;
        }
    }
    @media screen and (max-width:768px){
        overflow: hidden;
    }
}
