@import '../config';

.navigation--mobile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 40%;
	height: 100%;
	z-index: 200;
	padding-left: 0;
	margin-bottom: 0;
    margin-right: 0;
    margin-left: auto;
    background-color: #442310F2;
    @include max768px {
		height: 100%;
        width: 100%;
	}
	@media screen and (max-width: 1240px) {
		flex-flow: row wrap;
	}

	.navigation__list {
		flex-direction: column;
		justify-content: center;
		width: 100%;
        align-items: center;
        @media screen and (max-width:768px){
            margin-top: 7rem;
        }
	}

	.navigation__item {
		justify-content: center;
		padding: 0 15px;
        font-size: 1.5rem;
		margin-bottom: 30px;
		border-bottom: 0;
		border-top: 0;
		&.navigation__item--active {
			border-top: 0;
            border-bottom: 1px solid $lightbrown;
		}
	}

	.navigation__link {
		font-size: 1.5rem;
		@include max640px {
			font-size: 1rem;
		}
	}
}


body.hamburger-open {
	//overflow: hidden;
}

#overlay {
	display: none;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	height: 100%;
	
	z-index: 200;
	@media screen and (max-width: 1240px) {
		opacity: 1;
	}
}

/* fullscreen-menu */
/*----------------------------------*/
/*----------------------------------*/
/* hamburger-icon  */
.hamburger {
	display: none;
	position: relative;
	cursor: pointer;
	-webkit-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	margin: 0 1rem;
    z-index: 100;
    padding: 2px;
    display: flex;
    align-items: center;
	.hamburger__home-button {
		margin-right: 1.25rem;
		.hamburger__home-button-link {
			display: block;
			width: 25px;
		}
	}

	.hamburger__trigger {
		position: relative;
		width: 30px;
		height: 22px;
    }

	.hamburger__trigger--bread-top,
	.hamburger__trigger--meat,
	.hamburger__trigger--bread-bottom {
		position: absolute;
		display: block;
		width: 30px;
		height: 4px;
		opacity: 1;
		left: 0;
		border-radius: 0.5rem;
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
		-webkit-transition: .25s ease-in-out;
		transition: .25s ease-in-out;
		background-color: $white;
	}
}


.hamburger .hamburger__trigger--bread-top {
	top: 0;
}

.hamburger .hamburger__trigger--meat {
	top: 9px;
}

.hamburger .hamburger__trigger--bread-bottom {
	top: 18px;
}

.hamburger__trigger.animate-icon span:nth-child(1) {
	top: 10px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.hamburger__trigger.animate-icon span:nth-child(2) {
	opacity: 0;
	//right: -60px;
}

.hamburger__trigger.animate-icon span:nth-child(3) {
	top: 10px;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

/* hamburger-icons */
/*----------------------------------*/