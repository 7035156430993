@import '../config';

.footer {
    position: relative;
	background-color: transparent;
	z-index: 1;
	.footer__wrapper {
		padding: 3rem 1rem 8rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
	}
    .footer__logo {
        max-width: 100%;
        margin-bottom: 2rem;
    }
    .footer__socials {
        .socials {
            display: flex;
            gap: 1rem;
        }
        .socials__image {
            path {
                fill: $white;
            }
        }
    }
    .footer__copy {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        line-height: 16.8px;
        text-align: center;
        a {
            font-weight: 500;
        }
    }
    .footer__bg {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: -1;
    }
}

body:has(.winners__after), body:has(.winners__before)  {
    .footer__bg {
        display: none;
    }
}