.contact {
    width: 100%;
    padding: 0 0 0;
    background: transparent;
    color: $red;
    position: relative;
    z-index: 10;
    .contact__wrapper {
        height: 100%;
        max-width: 100%;
        padding: 5rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-inline: 0;
        &:has(.contact__wave) {
            padding-top: 0;
        }
    }
    p {
        color: $red;
        font-weight: 700;
        font-size: 24px;
        text-align: center;
        margin-top: 3rem;
        padding: 0 1rem;
        a {
            color: $red;
        }
        @media screen and (max-width:480px){
            font-size: 18px;
        }
    }
    * {
        position: relative;
        z-index: 1;
    }
    .form {
        max-width: 780px;
        width: 100%;
        padding: 0 1rem;
        .button {
            margin-top: 2rem;
        }
        .form__input {
            background: #A0000C;
            color: $light;
            border: 1px solid #EABB66;
            &::placeholder {
                color: rgba($color: #EABB66, $alpha: 0.5)
            }
        }
        .form__input--textarea {
            border-radius: 0;
        }
        .form__row--checkbox {
            label {
                color: $white;
            }
            input:is([type=checkbox]) {
                border-color: #EABB66;
                border-radius: 0;
                &:before {
                    content: url(../../images/ui/forms/check-white.svg);
                }
            }
        }
    }
    .contact__wave {
        width: 100%;
        margin-top: -5px;
    }
}