@import '../config';
@import '../core/breakpoints';

.modal {
    border: none;
    border-radius: 25px;
    &:focus-visible {
        outline: none;
    }
    .modal__content {
        padding: 2rem 4rem 2rem;
        @media screen and (max-width:768px){
            padding: 2rem 1rem;
        }
    }
    .close {
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        &:hover {
            path {
                fill: $red;
            }
        }
    }
    &::backdrop {
        background-color: #1D293980;
    }
}

.modal--products {
    width: 100%;
    max-width: 800px;
    h3 {
        color: $red;
        font-size: 36px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;
        text-transform: uppercase;
    }
    .modal__products {
        color: $red;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        max-height: 50vh;
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 1rem 0rem;
        &:focus-visible, &:focus-within, &:focus {
            outline: none;
            border: none;
        }
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: $red;
            border-radius: 10px;
            cursor: pointer;
            transition: 400ms ease-in-out;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: darken($color: $red, $amount: 10%)
        }

        &::-webkit-scrollbar-thumb:active {
            background: darken($color: $red, $amount: 10%)
        }
    }
    .modal__products--head {
        padding: 0;
        color: $brown;
        overflow-y: hidden;
    }
    .products__row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px 1rem;
        width: 100%;
        border-bottom: 1px solid $light;
        gap: 0.5rem 2rem;
        @media screen and (max-width:480px){
            flex-direction: column;
        }
        .product__ean {
            flex: 1;
            text-align: right;
        }
        .product__name {
            flex: 1;
            text-align: left;
            font-weight: 600;
        }
        @media screen and (max-width:768px){
            justify-content: flex-start;
            align-items: center;
            .product__ean, .product__name {
                flex: none;
            }
        }
    }
}